"use strict";

// AOS.init();

gsap.registerPlugin(ScrollTrigger);
gsap.defaults({
  ease: "power3"
});
// var desctop = window.matchMedia("(min-width: 1200px)");
$('[data-mcard-favorite]').on('click', function () {
  $(this).find('.favorite-icon').toggleClass('add-to-favorite');
});

var checkFavorite = function checkFavorite(input, icon) {
  input.is(':checked') ? icon.text('favorite').removeClass('c-dark').addClass('c-red') : icon.text('favorite_border').removeClass('c-red').addClass('c-dark');
};

$('[data-add-favorite]').each(function (index, item) {
  var input = $(item).find('.add-favorite__input');
  var icon = $(item).find('.add-favorite__icon');
  checkFavorite(input, icon);
  $(item).on('click', function () {
    checkFavorite(input, icon);
  });
});
$('[data-remove-favorite]').on('click', function (event) {
  event.stopPropagation();
  $(this).closest('.add-favorite__item').remove();
});
$('[data-switch]').on('click', function () {
  var input = $(this).find('input');

  if (input.prop('checked')) {
    $('body').toggleClass('light-theme');
  }
});

var headerFixed = function headerFixed() {
  var tl = gsap.timeline(),
    header = $('.header'),
    headerLogo = header.find('.header__icon');
  tl.to(headerLogo, .5, {
    width: '20rem',
    height: '4rem'
  });
};

var headerNormal = function headerNormal() {
  var tl = gsap.timeline(),
    header = $('.header'),
    headerLogo = header.find('.header__icon');
  tl.to(headerLogo, .5, {
    width: '27.5rem',
    height: '5.4rem'
  });
};

function playAudio(){

}

function initHeaderAnimation() {
  if (!$('.header').length) return;
  gsap.to(".header", {
    scrollTrigger: {
      trigger: ".header",
      start: "+=1",
      end: "top",
      onEnter: function onEnter() {
        $('.header').addClass('fixed');
        headerFixed();
      },
      onEnterBack: function onEnterBack() {
        $('.header').removeClass('fixed');
        headerNormal();
      }
    }
  });
}

$(function () {

  ScrollTrigger.matchMedia({
    "(min-width: 320px)": function minWidth320px() {
      $('[data-header-btn]').on('click', function () {
        if (!$('body').hasClass('menu-open')) {
          gsap.to('.nav__wrap', .5, {
            delay: .5,
            zIndex: 2,
            opacity: 1,
            pointerEvents: 'all',
            onComplete: function onComplete() {
              $('body').addClass('menu-open');
            }
          });
          gsap.to('.nav__bg', 1, {
            height: '7rem',
            ease: Elastic.easeOut.config(1, 0.5)
          });
          gsap.to('.menu-btn__line_b', .25, {
            top: '1rem',
            rotation: 45
          });
          gsap.to('.menu-btn__line_t', .25, {
            top: '1rem',
            rotation: -45
          });
          gsap.to('.menu-btn__line_m', .25, {
            opacity: 0
          });
        } else {
          gsap.to('.nav__bg', .75, {
            height: '0',
            ease: Elastic.easeIn.config(1, 0.5),
            onComplete: function onComplete() {
              $('body').removeClass('menu-open');
            }
          });
          gsap.to('.nav__wrap', .5, {
            zIndex: -1,
            opacity: 0,
            pointerEvents: 'none'
          });
          gsap.to('.menu-btn__line_b', .25, {
            top: 'auto',
            bottom: '0rem',
            rotation: 0
          });
          gsap.to('.menu-btn__line_t', .25, {
            top: '0rem',
            rotation: 0
          });
          gsap.to('.menu-btn__line_m', .25, {
            delay: .25,
            opacity: 1
          });
        }

        ;
      });
      // if ($('.header').length) {
      //   gsap.to(".header", {
      //     scrollTrigger: {
      //       trigger: ".header",
      //       start: "+=1",
      //       end: "top",
      //       onEnter: function onEnter() {
      //         $('.header').addClass('fixed');
      //         headerFixed();
      //       },
      //       onEnterBack: function onEnterBack() {
      //         $('.header').removeClass('fixed');
      //         headerNormal();
      //       }
      //     }
      //   });
      // }
      if ($('.filter__btn').length) {
        gsap.to(".filter__btn", {
          scrollTrigger: {
            trigger: ".filter__btn",
            start: "+=1",
            end: "top",
            onEnter: function onEnter() {
              $('.filter__btn').addClass('fixed');
            },
            onEnterBack: function onEnterBack() {
              $('.filter__btn').removeClass('fixed');
            }
          }
        });
        $('.filter__btn').on('click', function (event) {
          if ($(this).hasClass('fixed')) {
            $(this).removeClass('fixed');
            var totalIndent = $('.header').outerHeight();
            $('html, body').stop().animate({
              scrollTop: totalIndent
            }, 500);
          }
        });
      }

      // if ($('.offers-header').length) {
      //   var windowHeight = $(window).outerHeight();
      //   var el = $('.offers-header');
      //   el.css('top', windowHeight - el.outerHeight());
      //   $(window).on('scroll resize', function () {
      //     // offersMain();
      //     // offersHeaderPos();
      //   });
      //   $('.js-offers-header-close').on('click', function (event) {
      //     event.stopPropagation();
      //     $('.offers-body').hide();
      //   });
      //   $('.offers-header').on('click', function (event) {
      //     event.stopPropagation();

      //     if ($(this).closest('.offers').hasClass('offers_main')) {
      //       $('html, body').stop().animate({
      //         scrollTop: $('.hero').outerHeight() - $('.header').outerHeight() - $('.offers-header__nav').outerHeight() * 2
      //       }, 500);
      //     } else {
      //       $(this).removeClass('fixed').addClass('static');
      //       $('.offers-body').show();
      //       var totalIndent = $('html').outerHeight() - $('.offers').outerHeight() - $('.header').outerHeight() - $('.footer').outerHeight();
      //       $('html, body').stop().animate({
      //         scrollTop: totalIndent
      //       }, 500);
      //     }
      //   });
      // }

      // ;

      // function offersMain() {
      // if ($('.offers_main').length) {
      //   var el = $('.offers_main');
      //   var el_head = el.find('.offers-header');
      //   var el_top = el.offset().top + el.height();
      //   var sc = $(window).scrollTop();

      //   if (sc > el_top && el_head.hasClass('nofixed')) {
      //     el_head.removeClass('nofixed');
      //   } else if (sc < el.offset().top - $('.header').outerHeight() && !el_head.hasClass('nofixed')) {
      //     el_head.addClass('nofixed');
      //   }
      // }
      // }

      // function offersHeaderPos() {
      // var documentHeight = $('html').outerHeight();
      // var windowHeight = $(window).outerHeight();
      // var el = $('.offers-header');

      // if (documentHeight - $('.footer').outerHeight() < $(window).scrollTop() + windowHeight) {
      //   el.css('top', documentHeight - $(window).scrollTop() - $('.footer').outerHeight() - el.outerHeight());
      // } else {
      //   el.css('top', windowHeight - el.outerHeight());
      // }
      // }

      // $(window).on('load', function () {
      //   var windowTotalHeight = $('html').outerHeight();
      //   $('html').on('click', function () {
      //     var newWindowTotalHeight = $('html').outerHeight();

      //     if (windowTotalHeight != newWindowTotalHeight) {
      //       $('.offers-header.fixed').css('opacity', 0);
      //       setTimeout(function () {
      //         offersHeaderPos();
      //         offersMain();
      //         $('.offers-header.fixed').css('opacity', 1);
      //         windowTotalHeight = $('html').outerHeight();
      //       }, 400);
      //     }
      //   });
      // });

    }
  });
})

var cardAnimations = function cardAnimations(elements) {
  var valY = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
  var valGrid = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [1, 3];
  gsap.set(elements, {
    y: valY
  });
  ScrollTrigger.batch(elements, {
    onEnter: function onEnter(batch) {
      return gsap.to(batch, {
        opacity: 1,
        y: 0,
        stagger: {
          each: 0.15,
          grid: valGrid
        },
        overwrite: true
      });
    },
    onLeave: function onLeave(batch) {
      return gsap.set(batch, {
        opacity: 0,
        y: -valY,
        overwrite: true
      });
    },
    onEnterBack: function onEnterBack(batch) {
      return gsap.to(batch, {
        opacity: 1,
        y: 0,
        stagger: 0.15,
        overwrite: true
      });
    },
    onLeaveBack: function onLeaveBack(batch) {
      return gsap.set(batch, {
        opacity: 0,
        y: valY,
        overwrite: true
      });
    }
  });
  ScrollTrigger.addEventListener("refreshInit", function () {
    return gsap.set(elements, {
      y: 0
    });
  });
};

if ($('.mcards__item').length) {
  cardAnimations('.mcards__item');
}

function initModals() {
  $('[data-modal]').click(function (event) {
    const $modal = $('#' + $(this).attr('data-modal'));
    let clickClose = $modal.attr('data-click-close') == 'true';
    $modal.modal({
      clickClose: clickClose,
      fadeDuration: 250
    });
    return false;
  });
}
function anAlert(bodyHtml, buttonText = "OK", title) {
  return new Promise((resolve, reject) => {
      $.confirm({
          title: title || "&nbsp;",
          content: bodyHtml,
          boxWidth: '600px',
          useBootstrap: false,
          closeIcon: true,
          buttons:
          {
              ok: {
                  text: buttonText,
                  btnClass: 'btn w-300 h-50 maw-100p btOK',
                  keys: ['enter', 'space'],
                  action: function () {
                      resolve( true);  
                  }
              }
          }
      });
  })
}
function anAlertOld(bodyHtml, buttonText = "") {
  if (bodyHtml.indexOf('<')<0 && bodyHtml.indexOf('>')<0) {
    bodyHtml = `<div class="mb-2">${bodyHtml}</div>`;
  }
  const $modal = $(`
	<div id="anAlert" class="f-modal" style="display:none;">
		<div class="modal-content w-480">
			<div class="modal-header">
				<div data-fancybox-close class="modal-close"><svg class="icon"><use xlink:href="#times-light"></use></svg></div>
			</div>

			<div class="text-center">
        ${bodyHtml}
				<a data-fancybox-close href="javascript:void(0)" class="btn w-300 h-50 maw-100p btOK">${buttonText||"OK"}</a>
			</div>
		</div>
	</div>`);
  $("body").prepend($modal);
  var def = $.Deferred();
  $modal.modal({
    clickClose: false,
    fadeDuration: 250
  });
  $modal.on($.modal.CLOSE, () => {
    $.modal.close();
    $modal.remove();
    def.reject(false);
  });
  $modal.find(".btOK").on("click", () => {
    def.resolve(true);
	  $.modal.close();
    $modal.remove();
  });
  return def.promise();
}
function openModal(selector,closeFn,bodyFn) {
  const $modal = $(selector);
  var def = $.Deferred();
  let clickClose = $modal.attr('data-click-close') == 'true';
  $modal.modal({
    clickClose: clickClose,
    fadeDuration: 250
  });
  if (bodyFn) {
    bodyFn(def);
  }
  if (closeFn) {
    $modal.on($.modal.CLOSE, () => { closeFn(def) });
  } else {
    $modal.on($.modal.CLOSE, () => {
      def.reject(false)
    });
  }
  if (!closeFn && !bodyFn) {
    def.resolve(true);
  }
  return def.promise();
}

function closeModal() {
  $.modal.close();
}

$('[data-show-pass]').on('click', function () {
  $('.js-pass-view').toggleClass('ic-eye-off ic-eye');
  $('[data-show-pass]').parent().find('input').attr('type', $('[data-show-pass]').parent().find('input').attr('type') == 'password' ? 'text' : 'password');
});

// if ($('.ddown').length) {
//   $('.ddown').dropdown();
// }

function menuCenter(element) {
  var menu = element.find('.menu.center');

  if (menu.length) {
    var pw = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    var elWidth = element.outerWidth() / 2;
    var menuWidth = menu.outerWidth() / 2;
    var count = pw * 10 / 1440;
    var delta = (menuWidth - elWidth) / count;
    menu.css('left', -delta + 'rem');
  }
};

function initContextMenu() {
  setTimeout(() => {
    $('.cmenu-hover').dropdown({
      on: 'hover',
      duration: 200,
      onShow: function onShow() {
        menuCenter($(this));
        $(this).on('click', function () {
          $(this).dropdown('hide');
        });
      }
    });
    $('.cmenu-def').dropdown({
      onShow: function onShow() {
        menuCenter($(this));
      }
    });
  }, 200)
}

function initAccordion() {
  $(function () {
    $('.accordion').accordion({
      onChanging: function onChanging() {
        $(this).closest('.accordion').toggleClass('is-active');
        
      }
    });
    $('[data-accordion-close]').on('click', function (evt) {
      
      evt.preventDefault();
      $('.accordion').accordion('close', 0);
    });
  });
}

function initDropdowns() {
  $(function () {
    $('.ddown').dropdown();
    $('.cmenu-def').dropdown({
      onShow: function onShow() {
        menuCenter($(this));
      }
    });
  });
}

function initFilterButton() {
  const btn = document.querySelector('.filter__btn');
  if (!btn) return;
  const filterElement = document.querySelector('.filter');
  const headerElement = document.querySelector('.header');
  const toggleFilterButton = _ => {
    const edgeY = filterElement.offsetTop - headerElement.offsetHeight - 32;
    if (window.scrollY > edgeY) {
      if (!btn.classList.contains('fixed')) {
        btn.classList.add('fixed');
      }
    } else {
      if (btn.classList.contains('fixed')) {
        btn.classList.remove('fixed');
      }
    }
  };

  window.addEventListener('scroll', toggleFilterButton);
  toggleFilterButton();

  btn.addEventListener('click', _ => {
    if (!btn.classList.contains('fixed')) return;
    $('html, body').stop().animate({
      scrollTop: filterElement.offsetTop - headerElement.offsetHeight - 32
    }, 500);
  });

  $(document).mouseup(function (e) {
    var div = $(".filter-components");
    if (!div.is(e.target) && div.has(e.target).length === 0) {
      document.querySelector(".filter-components .content")?.classList.remove("active");
    }
  });
}

function initDatepicker() {
  if ($('[data-toggle="datepicker"]').length) {
    $.fn.datepicker.languages['ru-RU'] = {
      format: 'dd.mm.YYYY',
      days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
      daysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
      daysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
      months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
      weekStart: 1,
      startView: 0,
      yearFirst: false,
      yearSuffix: ''
    };
    $('[data-toggle="datepicker"]').datepicker({
      language: 'ru-RU',
      autoHide: true
    });
  }
}

function closeMenu() {
  if ($('.nav__bg').length) {
    gsap.to('.nav__bg', .75, {
      height: '0',
      ease: Elastic.easeIn.config(1, 0.5),
      onComplete: function onComplete() {
        $('body').removeClass('menu-open');
      }
    });
  }
  if ($('.nav__wrap').length) {
    gsap.to('.nav__wrap', .5, {
      zIndex: -1,
      opacity: 0,
      pointerEvents: 'none'
    });
  }
  if ($('.menu-btn__line_b').length) {
    gsap.to('.menu-btn__line_b', .25, {
      top: 'auto',
      bottom: '0rem',
      rotation: 0
    });
  }
  if ($('.menu-btn__line_t').length) {
    gsap.to('.menu-btn__line_t', .25, {
      top: '0rem',
      rotation: 0
    });
  }
  if ($('.menu-btn__line_m').length) {
    gsap.to('.menu-btn__line_m', .25, {
      delay: .25,
      opacity: 1
    });
  }
}

function openMenu() {
  $('body').addClass('menu-open');
  if ($('.nav__wrap').length) {
    gsap.to('.nav__wrap', .5, {
      delay: .5,
      zIndex: 2,
      opacity: 1,
      pointerEvents: 'all',
    });
  }
  if ($('.nav__bg').length) {
    gsap.to('.nav__bg', 1, {
      height: '7rem',
      ease: Elastic.easeOut.config(1, 0.5)
    });
  }
  if ($('.menu-btn__line_b').length) {
    gsap.to('.menu-btn__line_b', .25, {
      top: '1rem',
      rotation: 45
    });
  }
  if ($('.menu-btn__line_t').length) {
    gsap.to('.menu-btn__line_t', .25, {
      top: '1rem',
      rotation: -45
    });
  }
  if ($('.menu-btn__line_m').length) {
    gsap.to('.menu-btn__line_m', .25, {
      opacity: 0
    });
  }
}

function initSlider() {
  if ($('[data-gallery]').length) {
    $('[data-gallery]').each(function () {
      var arrows = $(this).data('arrows');
      var appendArrows;
      var dots = $(this).data('dots');
      var appendDots;

      if (arrows != undefined) {
        arrows = true;
        appendArrows = $(this).closest('.gallery').find('.gallery__arrows');
      } else {
        arrows = false;
      }

      if (dots != undefined) {
        dots = true;
        appendDots = $(this).closest('.gallery').find('.gallery__dots');
      } else {
        dots = false;
      }

      $(this).slick({
        autoplay: true,
        autoplaySpeed: 2000,
        rows: 0,
        arrows: arrows,
        appendArrows: appendArrows,
        dots: dots,
        appendDots: appendDots
      });
    });
  }
}

$(window).on('load', function () {
  var windowTotalHeight = $('html').outerHeight();
  $('html').on('click', function () {
    var newWindowTotalHeight = $('html').outerHeight();

    if (windowTotalHeight != newWindowTotalHeight) {
      $('.offers-header.fixed').css('opacity', 0);
      setTimeout(function () {
        windowTotalHeight = $('html').outerHeight();
      }, 400);
    }
  });
  $('.ucword').each(w => w.text(ucwords(w)));
});

function ucfirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function ucwords(str) {
  if (!str) return;
  return str
    .trim()
    .toLowerCase()
    .replace(/[A-Za-zА-Яа-я0-9]+/g, function ($1) {
      return ucfirst($1);
    });
}

function ttInit() {
  const box = document.createElement("div");
  box.id = 'tt';
  box.classList.add('tt');
  document.body.appendChild(box);
  return box;
}

function ttTurnOn(event) {
  let element = event.target;
  let content = element.getAttribute('data-tooltip');
  if (!content) {
    element = element.closest('[data-tooltip]');
    if (!element) return;
    content = element.getAttribute('data-tooltip');
  }
  if (!content) return;

  const below = String(element.getAttribute('data-position') ?? '').indexOf('bottom') >= 0;

  const boundBox = element.getBoundingClientRect();
  const maxOffsetX = 45;
  let offsetX = boundBox.width * .2;
  if (offsetX > maxOffsetX) offsetX = maxOffsetX;
  const coordX = boundBox.right - offsetX;
  const offsetY = 5;
  const coordY = below ? boundBox.bottom + offsetY : boundBox.top - window.ttBox.clientHeight - offsetY;

  window.ttBox.style.left = `${coordX}px`;
  window.ttBox.style.top = `${coordY}px`;

  window.ttBox.innerHTML = content;

  setTimeout(() => {
    window.ttBox.style.visibility = 'visible';
    // window.ttBox.style.transform = 'scale(1)';
    window.ttTarget = element;
  }, 50);

}

function ttTurnOff(event) {
  if (event?.target) {
    if (event.target == window.ttBox || event.target == window.ttTarget) return;
    if (event.target.closest && event.target.closest('[data-position]') == window.ttTarget) return;
  }

  // window.ttBox.style.transform = 'scale(.5)';
  window.ttBox.style.visibility = 'hidden';
  window.ttTarget = null;
};

window.ttBox = ttInit();
window.ttTarget = null;

window.addEventListener('mouseover', event => {
  ttTurnOn(event);
});

window.addEventListener('mouseout', event => {
  ttTurnOff(event);
});

window.addEventListener('scroll', _ => {
  ttTurnOff();
});

window.addEventListener('click', _ => {
  ttTurnOff();
}, true);

// function totalFontSize() {
//   const pageWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

//   if (pageWidth > 998) {
//     let value = (pageWidth * 62.5 / 1440).toFixed(2);
//     document.documentElement.style.fontSize = value + '%';
//   } else {
//     document.documentElement.style.fontSize = '62.5%';
//   }
// };

// window.addEventListener('DOMContentLoaded', function () {
//   totalFontSize();
// });

// window.addEventListener('resize', function () {
//   totalFontSize();
// });

function initRangeSliders(edges) {

  $(function () {
    function del_price_f(el) {
      var v = $("#js-range-slider").data("ionRangeSlider");
      var min_price = $('#js-range-slider').data('start');
      v.update({
        from: min_price
      });
      $(".rang_slider_from1").prop('value', min_price);
      jQuery(el).parent().remove();
    }

    function del_price_t(el) {
      var v = $("#js-range-slider").data("ionRangeSlider");
      var max_price = $('#js-range-slider').data('finish');
      v.update({
        to: max_price
      });
      $(".rang_slider_to1").prop('value', max_price);
      jQuery(el).parent().remove();
    }

    var $range = $("#js-range-slider"),
      $inputFrom1 = $(".rang_slider_from1"),
      $inputTo1 = $(".rang_slider_to1"),
      instance,
      min = $('#js-range-slider').data('start'),
      max = $('#js-range-slider').data('finish'),
      from = $('#js-range-slider').data('from'),
      to = $('#js-range-slider').data('to');
    $range.ionRangeSlider({
      skin: "round",
      type: "double",
      min: edges.minPrice,
      max: edges.maxPrice,
      from: edges.minPrice,
      to: edges.maxPrice,
      step: 1,
      onStart: updateInputs,
      onChange: updateInputs
    });
    instance = $range.data("ionRangeSlider");

    function updateInputs(data) {
      from = data.from;
      to = data.to;
      $inputFrom1.prop("value", from);
      $inputFrom1[0].dispatchEvent(new Event('input'));
      $inputTo1.prop("value", to);
      $inputTo1[0].dispatchEvent(new Event('input'));

      if (from == $('#js-range-slider').data('start')) {
        if (jQuery('#block_chip').find('.chip_pf')) {
          jQuery('#block_chip').find('.chip_pf').remove();
        }
      } else {
        if (jQuery('#block_chip').find('.chip_pf').length) {
          jQuery('#block_chip').find('.chip_pf .chip_text').text('Цена от ' + from + ' млн, ₽');
        } else {
          jQuery('#block_chip').append('<div class="chip chip_pf"><span class="chip_text">Цена от ' + from + ' млн, ₽</span><span class="closebtn" onclick="del_price_f(this);">&times;</span></div>');
        }
      }

      if (to == $('#js-range-slider').data('finish')) {
        if (jQuery('#block_chip').find('.chip_pt').length) {
          jQuery('#block_chip').find('.chip_pt').remove();
        }
      } else {
        if (jQuery('#block_chip').find('.chip_pt').length) {
          jQuery('#block_chip').find('.chip_pt .chip_text').text('Цена до ' + to + 'млн, ₽');
        } else {
          jQuery('#block_chip').append('<div class="chip chip_pt"><span class="chip_text">Цена до ' + to + 'млн, ₽</span><span class="closebtn" onclick="del_price_t(this);">&times;</span></div>');
        }
      }
    }

    function del_price_f2(el) {
      var v = $("#js-range-slider2").data("ionRangeSlider");
      var min_price = $('#js-range-slider2').data('start');
      v.update({
        from: min_price
      });
      $(".rang_slider_from2").prop('value', min_price);
      jQuery(el).parent().remove();
    }

    function del_price_t2(el) {
      var v = $("#js-range-slider2").data("ionRangeSlider");
      var max_price = $('#js-range-slider2').data('finish');
      v.update({
        to: max_price
      });
      $(".rang_slider_to2").prop('value', max_price);
      jQuery(el).parent().remove();
    }

    var $range = $("#js-range-slider2"),
      $inputFrom2 = $(".rang_slider_from2"),
      $inputTo2 = $(".rang_slider_to2"),
      instance,
      min = $('#js-range-slider2').data('start'),
      max = $('#js-range-slider2').data('finish'),
      from = $('#js-range-slider2').data('from'),
      to = $('#js-range-slider2').data('to');
    $range.ionRangeSlider({
      skin: "round",
      type: "double",
      min: edges.minCertPrice,
      max: edges.maxCertPrice,
      from: edges.minCertPrice,
      to: edges.maxCertPrice,
      step: 1,
      onStart: updateInputs2,
      onChange: updateInputs2
    });
    instance = $range.data("ionRangeSlider");

    function updateInputs2(data) {
      from = data.from;
      to = data.to;
      $inputFrom2.prop("value", from);
      $inputFrom2[0].dispatchEvent(new Event('input'));
      $inputTo2.prop("value", to);
      $inputTo2[0].dispatchEvent(new Event('input'));

      if (from == $('#js-range-slider2').data('start')) {
        if (jQuery('#block_chip').find('.chip_ff').length) {
          jQuery('#block_chip').find('.chip_ff').remove();
        }
      } else {
        if (jQuery('#block_chip').find('.chip_ff').length) {
          jQuery('#block_chip').find('.chip_ff .chip_text').text('Этаж от ' + from);
        } else {
          jQuery('#block_chip').append('<div class="chip chip_ff"><span class="chip_text">Этаж от ' + from + '</span><span class="closebtn" onclick="del_f_f2(this);">&times;</span></div>');
        }
      }

      if (to == $('#js-range-slider2').data('finish')) {
        if (jQuery('#block_chip').find('.chip_ft').length) {
          jQuery('#block_chip').find('.chip_ft').remove();
        }
      } else {
        if (jQuery('#block_chip').find('.chip_ft').length) {
          jQuery('#block_chip').find('.chip_ft .chip_text').text('Этаж до ' + to);
        } else {
          jQuery('#block_chip').append('<div class="chip chip_ft"><span class="chip_text">Этаж до ' + to + '</span><span class="closebtn" onclick="del_f_t2(this);">&times;</span></div>');
        }
      }
    }

    function del_price_f3(el) {
      var v = $("#js-range-slider3").data("ionRangeSlider");
      var min_price = $('#js-range-slider3').data('start');
      v.update({
        from: min_price
      });
      $(".rang_slider_from3").prop('value', min_price);
      jQuery(el).parent().remove();
    }

    function del_price_t3(el) {
      var v = $("#js-range-slider3").data("ionRangeSlider");
      var max_price = $('#js-range-slider3').data('finish');
      v.update({
        to: max_price
      });
      $(".rang_slider_to3").prop('value', max_price);
      jQuery(el).parent().remove();
    }

    var $range = $("#js-range-slider3"),
      $inputFrom3 = $(".rang_slider_from3"),
      $inputTo3 = $(".rang_slider_to3"),
      instance,
      min = $('#js-range-slider3').data('start'),
      max = $('#js-range-slider3').data('finish'),
      from = $('#js-range-slider3').data('from'),
      to = $('#js-range-slider3').data('to');
    $range.ionRangeSlider({
      skin: "round",
      type: "double",
      min: edges.minCertQuantity,
      max: edges.maxCertQuantity,
      from: edges.minCertQuantity,
      to: edges.maxCertQuantity,
      step: 1,
      onStart: updateInputs3,
      onChange: updateInputs3
    });
    instance = $range.data("ionRangeSlider");

    function updateInputs3(data) {
      from = data.from;
      to = data.to;
      $inputFrom3.prop("value", from);
      $inputFrom3[0].dispatchEvent(new Event('input'));
      $inputTo3.prop("value", to);
      $inputTo3[0].dispatchEvent(new Event('input'));

      if (from == $('#js-range-slider3').data('start')) {
        if (jQuery('#block_chip').find('.chip_sf').length) {
          jQuery('#block_chip').find('.chip_sf').remove();
        }
      } else {
        if (jQuery('#block_chip').find('.chip_sf').length) {
          jQuery('#block_chip').find('.chip_sf .chip_text').text('Площадь от ' + from + ' м2');
        } else {
          jQuery('#block_chip').append('<div class="chip chip_sf"><span class="chip_text">Площадь от ' + from + ' м2</span><span class="closebtn" onclick="del_s_f3(this);">&times;</span></div>');
        }
      }

      if (to == $('#js-range-slider3').data('finish')) {
        if (jQuery('#block_chip').find('.chip_st').length) {
          jQuery('#block_chip').find('.chip_st').remove();
        }
      } else {
        if (jQuery('#block_chip').find('.chip_st').length) {
          jQuery('#block_chip').find('.chip_st .chip_text').text('Площадь до ' + to + ' м2');
        } else {
          jQuery('#block_chip').append('<div class="chip chip_st"><span class="chip_text">Площадь до ' + to + ' м2</span><span class="closebtn" onclick="del_s_t3(this);">&times;</span></div>');
        }
      }
    }
  });
}

function resetRangeSliders() {
  $("#js-range-slider").data("ionRangeSlider").reset();
  $("#js-range-slider2").data("ionRangeSlider").reset();
  $("#js-range-slider3").data("ionRangeSlider").reset();
}
